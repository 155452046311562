import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

class SkillzGameInfoTemplate extends React.Component {
  render() {
    const { data, location } = this.props
    const game = data.skillzgamesJson
    const siteTitle = data.site.siteMetadata.title

    console.log({ game })
    return (
      <Layout location={location} title={siteTitle}>
        <SEO
          title={game.name}
          // description={post.frontmatter.description || post.excerpt}
        />
        {/*
          <article
          className={`post-content ${post.frontmatter.thumbnail || `no-image`}`}
          >
          <header className="post-content-header">
            <h1 className="post-content-title">{post.frontmatter.title}</h1>
          </header>

          {post.frontmatter.description && (
            <p class="post-content-excerpt">{post.frontmatter.description}</p>
          )}

          {post.frontmatter.thumbnail && (
            <div className="post-content-image">
          <Img
          className="kg-image"
          fluid={post.frontmatter.thumbnail.childImageSharp.fluid}
          alt={post.frontmatter.title}
          />
            </div>
          )}

          <div
            className="post-content-body"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />

          <footer className="post-content-footer">
            There are two options for how we display the byline/author-info.
            If the post has more than one author, we load a specific template
            from includes/byline-multiple.hbs, otherwise, we just use the
            default byline.
          </footer>
        </article> */}
      </Layout>
    )
  }
}

export default SkillzGameInfoTemplate

export const pageQuery = graphql`
  query SkillzGameBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    skillzgamesJson(fields: { slug: { eq: $slug } }) {
      _id
      icon
      name
      ios
      android
      samsung
      cashEnabled
      datePublished
      description
      platform
      publisher
      favorite
      chatEnabled
      mcShortName
      plRanking
    }
  }
`
